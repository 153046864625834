@import '../../styles/helpers'

.loginPage
  display: flex
  align-items: center
  justify-content: center
  min-height: 100vh
  background-color: $n3

  .container
    background-color: $n
    padding: 2rem
    border-radius: 8px
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    text-align: center

    .logo
      max-width: 150px
      margin-bottom: 2rem

  .loginForm
    display: flex
    flex-direction: column
    align-items: center

    .label
      margin-bottom: 1rem
      width: 100%
      text-align: left

      .input
        width: 100%
        padding: 0.5rem
        margin-top: 0.5rem
        border: 1px solid $shades1
        border-radius: 4px

    .button
      padding: 0.75rem 1.5rem
      background-color: $p1
      color: $n
      border: none
      border-radius: 4px
      cursor: pointer
      transition: background-color 0.3s ease

      &:hover
        background-color: $p1

  +m
    .container
      width: 400px

  +dark
    background-color: $n8

    .container
      background-color: $n6
      color: $n

      .input
        background-color: $n7
        color: $n
        border: 1px solid $shades2

      .button
        background-color: $p1

        &:hover
          background-color: $p1

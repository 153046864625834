@import ../../styles/helpers

.container

  padding-left: 0

  +m
    padding-left: 0.5rem
.status
  +body2-s
  border-radius: 4px
@import ../../styles/helpers

.filter_container
  div[class*=top]
    display: none
  width: max-content
  left: -204px
  +m
    position: absolute
    left: unset
    height: 280px
    top: 40px
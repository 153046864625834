@import ../../../styles/helpers

.cell_empty
  display: block
  width: 400px
  +xl
    width: 180px
  +w
    width: 20px
  +x
    display: none
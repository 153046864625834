@import ../../../../../styles/helpers

.card
  min-height: 150px
  padding: 0
  padding-bottom: 24px
  +t
    margin-bottom: 0.5rem
  >div:first-child
    margin-bottom: -12px
    padding: 24px
    display: flex
    flex-basis: 100%
    flex-direction: row
    justify-content: space-between
  >div:last-of-type
    font-weight: 500

.maxHeight
  max-height: 30px
@import ../../../styles/helpers
.container
  display: none
  +x
    display: flex
    flex-direction: column
    width: 100%






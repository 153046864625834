
@import ../../../../../../styles/helpers

.services
  //text-overflow: ellipsis
  //overflow: hidden
  //white-space: nowrap
  //max-width: 25rem
  //width: initial
  &:hover
    +link

.name
  //width: inherit
  //text-overflow: ellipsis
  //overflow: hidden
  //white-space: nowrap



.services
  &:not(:first-of-type)
    margin-top: 1.5rem
.stagesCell
  display: flex
  flex-direction: row
  flex-direction: row
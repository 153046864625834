.container
  min-height: 100vh
  display: flex
  align-items: center
  justify-content: center
  background-color: #f5f5f5
  padding: 20px

.content
  text-align: center
  background: white
  padding: 40px
  border-radius: 8px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  max-width: 500px
  width: 100%

.icon
  color: #FF6B6B
  margin-bottom: 20px

.title
  font-size: 24px
  color: #2D3748
  margin-bottom: 16px

.message
  color: #4A5568
  margin-bottom: 24px
  line-height: 1.5

.timer
  color: #718096
  margin-bottom: 24px
  font-size: 14px

.button
  background-color: #4A5568
  color: white
  padding: 12px 24px
  border-radius: 6px
  border: none
  cursor: pointer
  transition: background-color 0.2s
  font-size: 14px

  &:hover
    background-color: #2D3748
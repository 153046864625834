@import ../../../../../../styles/helpers

.hoursView
  //+d
  //  &_container
  //    display: flex
  //    flex-direction: row
  //    gap: 60px
  display: flex
  flex-direction: row
  gap: 8px
  align-items: center
  &_container
    display: flex
    flex-direction: row
    //gap: 120px
  &_text
    white-space: nowrap
    +base2
    font-weight: 400
    span
      font-weight: 600
.add_button
  height: 40px
  >div
    height: 40px
  a
    height: 40px
  span
    font-weight: 700
    font-size: 13px
    line-height: 24px
.hoursExtra
  padding: 4px 0px
  align-items: center
.costs
  height: 24px
  align-items: center
  margin-left: 68px
  display: flex
  gap: 40px
.labelExtra
  flex-basis: 230px
.labelPlanned
  flex-basis: 230px
.hoursComponent
  display: contents

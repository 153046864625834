@import ../../styles/helpers
.title
  display: flex
  justify-content: space-between
  margin-bottom: 24px
  +m
    margin-bottom: 16px

.container
  align-items: center
  display: flex
  flex-direction: row
  gap: 20px

  .icon
    cursor: pointer
    display: flex
    align-self: center

.sortIcon
  display: none !important
  transition: ease-in-out .3s
  transform: rotateX(-180deg)
  &_active
    transform: rotateX(0deg)
  +x
    display: flex !important
.format_margin
  margin-bottom: 0


.settings
  transition-duration: 0.4s
  transform: rotate(0)
  &:hover
    transform: rotate(90deg)

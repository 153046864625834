@import "src/styles/helpers"
.name
  +title1-s
  color: $n7
  margin-bottom: 24px
.modal
  +body1-s
  p
    font-weight: 500


.content
  margin-bottom: 16px
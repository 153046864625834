@import ../../../../../../styles/helpers
.container
  display: none
  +m
    display: flex
    flex-direction: column
    width: 100%

.card
  padding: 16px
  margin-bottom: 12px
  width: 100%
  border-top-right-radius: 0px
  border-top-left-radius: 0px
  &_inner
    border-bottom: 1px $n3 solid
    margin-bottom: 12px
.header
  font-weight: 600
  line-height: 24px
  font-size: 18px
  margin-bottom: 12px
.body
  display: flex
  flex-direction: row
.name

.status,.sum
  &>span:first-child
    color: $shades2
  display: flex
  flex-direction: column
  gap: 2px
.status
  flex-basis: calc(100% - 120px)
//padding: 0
.avatar
  width: 32px
  height: 32px
.footer
  margin-top: 2.5rem
  margin-bottom: 16px
  display: flex
  justify-content: space-between
  align-items: center
.button
  +m
    display: block

.posLeft
  position: absolute
  left: 20px
@import "../../../../styles/helpers"

.callButtonContainer
  position: fixed
  left: 0px
  bottom: 00px
  z-index: 10

.callButton
  width: 40px
  height: 40px
  background-color: $p3
  //border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  transition: background-color 0.3s ease
  border: none

  &:hover
    background-color: darken($p3, 10%)
@import "../../../../../styles/helpers"
.container
  &_adaptive
    display: flex
    flex-direction: row
    justify-content: space-between
  +m
    display: none
  display: flex
  flex-direction: row
  gap: 40px
  align-items: center
  >:nth-child(2)
    font-size: 18px
    padding: 8px 12px
    align-self: center


.clientId
  +m
    display: none
  &_adaptive
    display: flex
    flex-direction: column
    align-self: end

  font-weight: 600
  font-size: 14px
  line-height: 14px
  display: flex
  flex-direction: column
  gap: 12px
  :nth-child(2)
    color: $shades2

.card
  justify-content: space-between
.adaptive
  display: none
  +m
    flex-basis: 100%
    display: flex
    flex-direction: column
    gap: 12px
.adaptive_badge
  flex-basis: 100%
  div
    width: 100%
    text-align: center

.right
  display: flex
  div[class*=ManagerCell_container]
    margin-right: 16px
    div[class*=ManagerCell_fioContainer]
      max-width: 100px

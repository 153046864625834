@import ../../../../styles/helpers
.container

  padding: 12px
.label
  +base2


.count
  display: inline-block
  padding-left: 8px
  color: #6F767E66
@import ../../styles/helpers

.commentList
  margin-top: 1rem
  margin-bottom: 1rem
  padding-bottom: 1rem
  max-height: 600px
  overflow-y: auto

  @include custom-scrollbar


.container
  margin-top: 1rem
  font-weight: 600
  color: $shades2
  display: flex
  gap: 24px
  flex-direction: column

.dateGroup
  margin-bottom: 24px

  &:last-child
    margin-bottom: 0

.dateHeader
  font-size: 16px
  color: #6F767E
  margin-bottom: 16px

.container
  margin-bottom: 16px

  &:last-child
    margin-bottom: 0

.empty
  text-align: center
  color: #6F767E
  padding: 24px
  font-size: 14px
@import ../../../../../../styles/helpers
.container
  display: none
  +x

    display: flex
    flex-direction: column
    width: 100%

.card
  padding: 16px
  margin-bottom: 12px
  width: 100%
  border-top-right-radius: 0px
  border-top-left-radius: 0px

.body
  border-bottom: 1px $n3 solid
  margin-bottom: 12px

.name

.status
  &_adaptiveStatus
    font-size: 18px !important
//padding: 0
.avatar
  width: 32px
  height: 32px
.footer
  margin-top: 2.5rem
  margin-bottom: 16px
  position: relative
  display: inline-flex
  //&>.avatar:last-of-type
  //  position: absolute
  //  left: 20px
.button
  +m
    display: block

.posLeft
  position: absolute
  left: 20px
@import ../../../../../../../../styles/helpers

.deadlineTimeCell
  //padding-left: unset
  text-align: center
  padding: 6px 18px !important
  border: 1px solid
  border-radius: 4px
  display: inline-block
  font-size: 14px
  min-width: 128px

.redDark 
  color: #C31212
  border-color: #C31212


.green 
  color: #26842A
  border-color: #26842A


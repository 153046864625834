.filtersContent 
  padding: 16px
  display: flex
  flex-direction: column
  gap: 12px


.radioOption 
  margin-bottom: 8px


.filterActions
  display: flex
  justify-content: space-between
  margin-top: 15px

.applyButton, .resetButton
  padding: 8px 16px
  border-radius: 4px
  cursor: pointer

.applyButton
  background-color: #4CAF50
  color: white
  border: none

.resetButton
  background-color: #f44336
  color: white
  border: none
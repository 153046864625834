@import ../../../../../../styles/helpers
.container
  display: flex
  flex-direction: row
  gap: 24px
.members
  color: $shades2
  fill: $shades2
  display: flex
  flex-direction: row
  gap: 6px

.tooltip
  position: relative
  top: -1px
  svg
    fill: $shades1
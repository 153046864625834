@import ../../../../../styles/helpers

.card
  display: none
.card_adaptive
  display: none
  +m
    display: block
.link
  +base1-s
.manager
  +caption1
@import "../../../../styles/helpers"

.phoneContactWrapper
  margin-top: 16px
  margin-bottom: 16px
  padding: 16px
  border-radius: 8px
  background-color: $n2
  +dark
    background-color: $n6

.phoneContactTitle
  margin-bottom: 12px
  color: $n7
  font-size: 15px
  font-weight: 600
  +dark
    color: $n1

.phoneContactList
  display: flex
  flex-direction: column
  gap: 8px

.phoneContactItem
  display: flex
  align-items: center
  padding: 8px 0

.phoneRadio
  width: 100%

.phoneInfo
  display: flex
  align-items: center
  margin-left: 8px
  width: 100%

.phoneNumber
  color: $n7
  font-size: 14px
  font-weight: 500
  +dark
    color: $n1
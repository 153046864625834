@import ../../../../../../styles/helpers

.name 
  display: flex
  justify-content: space-between

  .link
    flex-grow: 1.5
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    margin-right: 0.5rem
    &:hover
      cursor: pointer
      color: $p3
.moreCount
  position: relative
  top: 12px


.deadline 
  color: $shades2
  line-height: 12px
  font-weight: 600
  font-size: 12px


.activity 
  margin-bottom: 1rem


  



.activitiesCell 
  display: flex
  flex-direction: column


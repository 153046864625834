@import ../../../../../../styles/helpers
.container
  display: none
  +m
    display: flex
    flex-direction: column
    width: 100%
    border-top-right-radius: 0px
    border-top-left-radius: 0px


.card
  margin-bottom: 12px
  width: 100%
  border-bottom: 1px $n3 solid
  &:not(:first-of-type)
    margin-top: 1.0rem

.header
  font-weight: 600
  line-height: 24px
  font-size: 18px
  margin-bottom: 12px
.body

  display: flex
  flex-direction: row
  justify-content: space-between
  &:not(:last-of-type)
    margin-bottom: 12px
    padding-bottom: 12px
    border-bottom: 1px $n3 solid
  &>div:first-of-type
    //flex-grow: 1
    //flex-basis: 5rem
    flex-grow: 0.2
    flex-basis: 250px
    flex-shrink: 2
    flex-wrap: wrap
    //white-space: break-spaces
  &>div:nth-of-type(2)
    //flex-shrink: 1
    margin-right: 1rem
    margin-left: 1rem
  &>div:nth-of-type(3)
    //flex-shrink: 2
.name

.status,.sum
  &>span:first-child
    color: $shades2
  display: flex
  flex-direction: column
  gap: 2px
.status
  flex-basis: calc(100% - 120px)
//padding: 0
.avatar
  width: 32px
  height: 32px
.footer
  margin-top: 2.5rem
  margin-bottom: 16px
  display: flex
  justify-content: space-between
  align-items: center
.cell_manager
  font-weight: 600
  font-size: 13px
  line-height: 24px
  img
    width: 38px
    height: 38px
.date,.time
  margin-bottom: 0.5rem
  font-weight: 500
  color: $shades2
.button
  +m
    display: block
@import "../../../../../../../styles/helpers"

.filters
  display: inline-flex
  flex-direction: row
  background-color: $n
  border-radius: 8px
  margin-bottom: 10px
  padding: 8px

  .active
    .button
      padding: 0
      border: none
      background-color: #EFEFEF
      > div
        color: $n8 !important

  .button
    padding: 0
    border: none
    background-color: $n
    > div
      color: $n4 !important


.date_selector
  display: flex
  align-items: center
  gap: 8px
  padding: 8px 12px
  border-radius: 8px
  cursor: pointer
  transition: background-color 0.2s

  &:hover
    background-color: #F3F4F6

  &.active
    background-color: #EFEFEF

@import ../../styles/helpers
.container
  &:hover
    .fioContainer
      &>div:first-of-type
          +link
  //width: 9rem
  align-self: center
  +inter
  display: flex
  flex-direction: row
  gap: 12px
  height: 48px

  .fioContainer
    flex-grow: 3
    font-size: 14px
    align-content: center
    line-height: 18px
    .fio
      a,div
        cursor: auto !important
      margin-bottom: 4px
    .role
      +caption2-m
      color: $shades2
.name
  +caption1
  cursor: pointer
  text-decoration: none
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  a
    color: $n4
@import "../../../styles/helpers"

.cls
  div[class*='Module_buttons']
    margin: 0px 16px
    display: block
    justify-content: space-between
    div[class*='button']
      flex-basis: 100%
.container
  padding: 16px
  padding-top: 8px


.title
  +title1-s
  margin-bottom: 16px

.inputs
  display: flex
  gap: 12px

.input
  flex: 1
  min-width: 0
@import ../../../../styles/helpers
.link
  max-width: 20rem

  a
    color: $n8
    cursor: pointer

    &:hover
      color: $p3
    //&:visited
    //  color: $n8


.button
  span
    font-size: 13px
  padding: 0
  svg
    top: 1.5px
    left: 5.8px !important
  a
    height: 42px
.report_container
  display: flex
  flex-direction: row
  gap: 80px
  width: 100%
.button

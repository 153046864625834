@import ../../styles/helpers

.radio
  display: block
  position: relative
  user-select: none
  cursor: pointer
  -webkit-tap-highlight-color: rgba(0,0,0,0)

.input
  position: absolute
  top: 0
  left: 0
  opacity: 0
  &:checked + .inner .tick
    background: $p3
    border-color: $p3
    &:before
      transform: translate(-50%,-50%) scale(1)

.inner
  display: flex
  &:hover
    .tick
      border-color: $p3

.tick
  position: relative
  flex-shrink: 0
  width: 24px
  height: 24px
  border-radius: 50%
  border: 2px solid $shades3
  transition: all .2s
  &:before
    content: ""
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%) scale(0)
    width: 12px
    height: 12px
    border-radius: 50%
    background: $n1
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #DEDEDE
    transition: transform .2s

.text
  +base1-s
  flex-grow: 1
  padding-right: 16px
  line-height: (24/14)
  color: $n4
  +dark
    color: $n1

.radio_container

  label
    margin-bottom: 14px
    +body1-m
    color: $n4
    +dark
      color: $n3
  display: flex
  flex-direction: column
  .radio_buttons
    display: flex
    flex-direction: row
    gap: 8px

.errorInput
  input
    border-color: #ff5454 !important // Красная рамка при ошибке

@import "src/styles/helpers"
.title
  +title1-s

.card
  flex-shrink: 1
  flex-basis: 100%
  +t
    display: none
  div[class*=CardInput_label_multiple]
    padding-left: unset
    padding-right: unset
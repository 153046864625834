@import "src/styles/helpers"

.weekHeader
  display: flex
  border-bottom: 1px solid #E2E8F0
  padding: 8px 0  // Add padding to match Photo 1

.timeGutter
  width: 46px

  border-right: 1px solid #E2E8F0
  flex-shrink: 0

.dayColumn
  flex: 1
  border-right: 1px solid #E2E8F0
  min-width: 0
  padding: 0 8px  // Add horizontal padding to match Photo 1

.dayHeader
  display: flex
  align-self: center
  align-items: center
  padding: 8px 0
  text-align: center
  justify-content: center
  gap: 5px
  +body1-m
  color: $n7

  .dayNumber
      padding: 0px 3px
      display: block
      +body1-r
      color: $n4
  .isCurrentDay
    min-width: 24px
    background-color: $p3
    border-radius: 25px
    color: $n
@import "src/styles/helpers"
.widget
  display: flex
  align-items: center
  background-color: #ffffff
  border-radius: 8px
  padding: 16px

.icon
  position: relative
  width: 64px
  height: 64px
  border-radius: 50%
  margin-right: 16px
  display: flex
  align-items: center
  justify-content: center

.content
  flex: 1

.title
  +body1-m
  font-size: 14px
  color: $n7
  margin-bottom: 4px

.value
  font-size: 32px
  font-weight: 600
  line-height: 40px
  color: $n7
  .percent
    +body1-s
    padding-left: 8px
    &.accept
      color: #83BF6E
    &.reject
      color: #C31212



@import "src/styles/helpers"
.paginationButton
  position: relative
  display: flex
  align-items: center
  background: none
  border: none
  cursor: pointer
  color: #6F767E
  font-size: 16px
  padding: 8px
  transition: color 0.2s
  &:hover
    .label
      color: $p3
    .pagination
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%23FF6A55'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto !important


  &:disabled
    cursor: not-allowed
    .label
      color: $n4
    .pagination
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236F767E'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto !important


.label
  margin: 0 8px
  +base2
  color: $n7


.pagination
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%231A1D1F'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto !important

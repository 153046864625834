@import "src/styles/helpers"
.timetracking_container
  display: flex
  flex-direction: row
  gap: 8px
  margin-bottom: 16px
  >div
    flex-basis: 100%

  .stat_blue
    fill: $p1
  .stat_green
    fill: $p2
@import ../../../../../../styles/helpers

.container
  display: flex
  align-self: center
  align-items: center
  flex-direction: row
  border-bottom: 1px $n3 solid
  padding: 12px 0px

.label
  align-self: center
  +d
    flex-basis: 220px
  flex-basis: 230px
.children
  align-self: center
  width: 100%
.test
  width: 100%
  display: flex
  flex-direction: row
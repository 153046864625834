@import ../../../styles/helpers
.input
  input[type="file"]
    display: none

  svg
    &:hover
      path
        fill: $p1

.container
  align-self: center
@import "../../../../../../../../styles/helpers"
.dealList
  padding-bottom: 24px
  overflow-x: auto
  overflow-y: hidden
  display: flex
  white-space: nowrap
  gap: 24px
  scrollbar-width: none
  &::-webkit-scrollbar
    width: 0
.deal_note
  margin-top: 8px
  +caption2-m
  line-height: 14px
  color: $shades1
  word-break: break-word
.dealColumn
  padding-bottom: 48px
  min-width: 320px
  max-height: calc( 100vh - 96px - 40px - 105px)

.deal
  padding: 12px 16px
  margin-bottom: 10px
  background-color: #f9f9f9
  white-space: normal

  cursor: pointer
  &_source
    margin-bottom: 12px
  &_price
    +body1-m
    color: $n4
    font-weight: 600
    margin-top: 8px

  &_assignee
    margin-top: 16px
    display: flex
    //align-items: center
    gap: 8px

    .avatar
      margin: unset
    &_name
      +body1-m
      color: $n7
.divider
  min-width: 320px
  height: 4px
  border-radius: 0px
  padding: 0px

.header
  +base1-s
  font-size: 15px
.counts
  color: $n4

.scroll
  overflow-y: auto
  max-height: 100%
  scrollbar-width: none
  &::-webkit-scrollbar
    width: 0

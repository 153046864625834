@import ../../../styles/helpers

.file
  cursor: pointer
  background: $n2
  min-height: 35px
  max-height: 40px
  max-width: 200px
  flex-basis: 200px
  display: flex
  padding: 8px 16px
  border-radius: 8px
  &:hover
    background: $n3
  &_name
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    padding-left: 1rem
    padding-right: 1rem
    color: $n7
    font-weight: 600
  &_icon

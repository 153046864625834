@import "src/styles/helpers"
.container
  display: flex
  flex-direction: row
  gap: 8px
  margin-bottom: 16px
  >div
    flex-grow: 1

.callDate
  margin-top: 8px
  +caption1
.icon
  width: 36px
  height: 36px
.info
  +caption2-m
  color: $shades2
.fioContainer
  white-space: nowrap

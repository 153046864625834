@import src/styles/helpers

.radio_container

  label
    margin-bottom: 14px
    +body1-m
    color: $n4
    +dark
      color: $n3
  display: flex
  flex-direction: column
  .radio_buttons
    display: flex
    flex-direction: row
    gap: 8px

.errorInput
  input
    border-color: #ff5454 !important // Красная рамка при ошибке

@import ../../../../../styles/helpers
.card
  display: none
.cell_manager
  min-width: 200px
  font-weight: 600
  font-size: 13px
  line-height: 24px
  img
    width: 38px
    height: 38px
.header
  //&:not(:first-of-type)
  &_first

    padding-top: 0
    padding-bottom: 0
  padding-top: 24px
  padding-bottom: 18px
  +caption1-m
  color: $shades2
.cell_empty
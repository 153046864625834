// src/components/CallModal/CallModal.module.sass
@import "../../../../styles/helpers"

.modalOverlay
  position: fixed
  left: 40px
  bottom: 40px
  display: flex
  align-items: center
  justify-content: center
  z-index: 10
  animation: fadeIn 0.3s ease-out


.modal
  background-color: $n1
  width: 340px
  max-height: 60vh
  display: flex
  flex-direction: column
  border-top-left-radius: 16px
  border-top-right-radius: 16px
  overflow-y: scroll

  box-shadow: 2px 8px 32px rgba(0, 0, 0, 0.15)
  animation: slideIn 0.3s ease-out
  +dark
    background-color: $n7

.header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px
  border-bottom: 1px solid $n3
  +dark
    border-color: $n6

.tabs
  display: flex
  gap: 16px

.tab
  display: flex
  align-items: center
  gap: 8px
  padding: 8px 12px
  background: none
  border: none
  border-radius: 8px
  color: $n4
  cursor: pointer
  transition: all 0.2s ease

  &:hover
    color: $n7
    +dark
      color: $n1

  &.active
    color: $p3
    background-color: rgba($p3, 0.1)

.closeButton
  width: 32px
  height: 32px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  background: none
  border: none
  cursor: pointer
  color: $n4

  &:hover
    background-color: $n2
    color: $n7
    +dark
      background-color: $n6
      color: $n1

.content
  flex: 1
  overflow-y: auto
  padding: 16px

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes slideIn
  from
    transform: translateY(20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1



.tableContainer
  div[class*="gridContainer"]
    display: grid
    grid-template-columns: 3fr 1fr
    gap: 16px
    div[class*='pagingCard']
      grid-area: 2
      margin-top: unset
      display: flex
      div[class*='Table_pagination']
        flex-basis: 100%
        margin-top: auto
        margin-bottom: auto


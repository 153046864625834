@import ../../../../../../styles/helpers
.container
  display: none
  a>div
    border-bottom: $n3 1px solid
  a:first-of-type>div
    border-bottom-right-radius: 0
    border-bottom-left-radius: 0
  a:last-of-type>div
    border-bottom: none
    border-top-right-radius: 0
    border-top-left-radius: 0
  +d
    display: flex
    flex-direction: column
    width: 100%

.card
  color: $n7
  padding: 16px
  width: 100%

.name

.status
  &_adaptiveStatus
    font-size: 18px !important
//padding: 0
.avatar
  width: 32px
  height: 32px
.footer
  margin-top: 2.5rem
  margin-bottom: 16px
  display: flex
  justify-content: space-between
  align-items: center
.header
  &>*:last-of-type
    margin-right: 4px
  display: flex
  justify-content: space-between
.deadline
  color: $n4
@import ../../../styles/helpers

.component
  +base1-s
  display: flex
  gap: 8px
  background-color: $n1
  border-radius: 4px
  overflow: hidden
  +m
    display: flex
    flex-direction: column
    max-width: 250px

  .tab 
    display: flex
    align-items: center
    padding: 8px 12px
    cursor: pointer
    border-radius: 8px

    &:hover
      &:not(.active)
        color: $ph1
        svg
          fill: $ph1

    .icon 
      //font-size: 16px
      //margin-right: 8px
  

    .label
      margin-left: 0.5rem
      //display: flex
      //font-size: 14px
      //font-weight: 500
  

    .count 
      //background-color: #ff6347
      //color: white
      padding: 2px 6px
      border-radius: 10px
      font-size: 12px
      margin-left: 4px
  
.active
  background: $n3



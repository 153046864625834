.confirmationOverlay
  background: rgba(0, 0, 0, 0.5)
  backdrop-filter: blur(4px)
  position: absolute
  align-items: center
  justify-content: center
  z-index: 1100
  display: contents

.confirmationContent
  text-align: center
  padding: 20px
  h3
    margin: 0
    font-size: 18px

.confirmationButtons
  display: flex
  width: 100%
  gap: 16px
  justify-content: center
  margin-top: 20px

.actionButtons
  display: flex
  gap: 16px
  margin-right: auto

.actionButton
  display: flex
  align-items: center
  gap: 8px
  cursor: pointer
  padding: 8px 12px
  border-radius: 4px
  transition: background-color 0.2s
  &:hover
    background-color: rgba(0, 0, 0, 0.05)
  span
    font-size: 14px
.confirmButton,.cancelButton
  width: 100%
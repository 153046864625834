.container
  font-weight: 500
  line-height: 18px
  font-size: 13px
  display: flex
  flex-direction: column
  gap: 12px
  padding: 16px

.input
  align-items: center
  display: flex
  gap: 12px

  &_field
    display: flex
    flex: 1
    gap: 12px

    .time_input
      flex: 1
      min-width: 0

  &_send
    flex-shrink: 0
    cursor: pointer

.send
  cursor: pointer
  &:hover
    fill: #333

.send_disabled
  cursor: not-allowed
  opacity: 0.5
@import ../../../../../../styles/helpers

.button
  &_container
    margin-right: 12px
    +d
      flex-basis: 280px !important
  +d
    max-width: 10px

  max-width: 170px
  display: flex
  flex-direction: row
  +d
    justify-content: flex-start
    a
      display: inline-flex
    max-width: 130px
    a
      margin-left: 0
    svg
      display: none

  span
    text-align: start
    min-width: 120px
    font-size: 13px
  padding: 0
  padding-left: 0.8rem
  height: 40px
  a
    padding: 0
    height: 40px
  svg
    padding-right: 4px
    fill: $n4

.act_container
  width: 100%
  display: flex
  flex-direction: row

.statusContainer
  padding-left: 48px
  display: flex
  flex-direction: row
  gap: 12px
.act_main

  //width: 100%

.label

  flex-basis: 230px


@import "src/styles/helpers"
.contextMenu
  top: 50px
  right: 40px
  position: absolute
  background: white
  border: 1px solid #e0e0e0
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1)
  border-radius: 4px
  z-index: 1000
  overflow: hidden
  padding-left: 0 !important

  .menuList 
    list-style: none
    margin: 0
    padding: 0

    .menuItem 
      padding: 8px 16px
      cursor: pointer
      color: $n4
      transition: background-color 0.2s
      +base2

      &:hover 
        background-color: $n3
        color: $n7
    

      &.disabled
        display: none
        color: #ccc
        cursor: not-allowed
    
  


